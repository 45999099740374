<template>
  <h3>Version 1.0.0</h3>
  <p>More information avaiable on:<br />- https://webialisten.de</p>
  <router-link to="/">Go Back</router-link>
</template>

<script>
export default {
  name: 'AboutView',
  inheritAttrs: false, // disable 'non-props' warning
};
</script>