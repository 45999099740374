<template>
    <footer>
        <p>Copyright &copy; 2022</p>
        <router-link to="/about">About Information</router-link>
         - 
        <router-link to="/alltasks">All Tasks</router-link>
    </footer>
</template>

<script>

export default {
    name: 'FooterTemplate',
}
</script>

<style scoped>
    a {
        color: #333;
    }
    footer {
        margin-top: 30px;
        text-align: center;
    }
</style>