<template>
  <h3>Show open Tasks</h3>
  <Tasks
    @toggle-reminder="toggleReminder"
    :tasks="tasks"
  />
  
</template>

<script>
import Tasks from '../components/Tasks'

export default {
  name: 'OpenTasks',
  inheritAttrs: false, // disable 'non-props' warning
   components: {
    Tasks
  },
  data() {
    return {
      tasks: [],
    }
  },
  methods: {
    async toggleReminder(id) {
      const taskToToggle = await this.fetchTask(id)
      const updTask = { ...taskToToggle, reminder: !taskToToggle.reminder }
      const res = await fetch(`api/tasks/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(updTask),
      })
      const data = await res.json()
      this.tasks = this.tasks.map((task) =>
        task.id === id ? { ...task, reminder: data.reminder } : task
      )
    },
    async fetchTasks() {
      const res = await fetch('api/tasks')
      const data = await res.json()
      var data2 = data.filter(function(task){
        return !task.reminder 
      })
      return data2
    },
    async fetchTask(id) {
      const res = await fetch(`api/tasks/${id}`)
      const data = await res.json()
      return data
    },
  },
  async created() {
    this.tasks = await this.fetchTasks()
  },
};

</script>