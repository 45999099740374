<template>
    <div @dblclick="$emit('toggle-reminder', task.id)" :class="[task.reminder ? 'reminder' : '', 'task']">
        <h3>{{task.text}}
            <i @click="$emit('delete-task', task.id)" class="fas fa-times"></i>
        </h3>
        <p>{{task.day ? "Bis: " + task.day : ""}}</p>
        <p>{{task.wheretofind ? "Wo: " + task.wheretofind : ""}}</p>
    </div>
</template>


<script>
export default {
  name: 'TaskTemplate',
  props: {
    task: Object,
  },
  methods: {

  }
}
</script>

<style scope>
    .fas {
    color: red;
    }
    .task {
    background: #f4f4f4;
    margin: 5px;
    padding: 10px 20px;
    cursor: pointer;
    }
    .task.reminder {
      border-left: 5px solid green;
      text-decoration: line-through;
    }
    .task h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    }
</style>